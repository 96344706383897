.search-input {
    width: 100%;
    /* height: 65px; */
    height: calc(2.25rem + 2px);
    border-radius: 100px;
    border: none;
    padding-top: 1rem;
    padding-left: 2.25rem;
}

@media only screen and (min-device-width: 480px) and (max-width: 769px){
    .search-input {
        padding-top: 0px;
    }
}

.search-input:focus {
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.autocomplete-dropdown-container {
    position: absolute;
    z-index: 99999;
    border-color: red;
    border-width: 1px;
    margin-top: 1rem;
    margin-left: 10px;
}

.suggestion-cont {
    padding: 15px;
}
.found-results{
    
}