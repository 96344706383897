.circle {
  background-color: #EDEDED;
  border-radius: 100%;
  width: 80px;
  height: 80px;
}


.center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto
}

.center2 {
  position: absolute;
  top: 32%;
  left: 35%;
}

.add-icon {
  font-size: 30px;
  color: #D5D5D5
}

.select-outer {
  margin-top: 20px;
  border: 1px solid #EDEDED;
  border-radius: 5%;
  /* margin: 5px; */
  cursor: pointer;
  min-height: 400px;
  /* height: 19em;  */
}

.row-non-primary {
  /* margin-top: -12px; */
  margin-left: 12%;
  background-color: white;
  width: 80%;
}

.select-outer-primary {
  border: 1px solid #EDEDED;
  background-color: #007ABB;
  border-radius: 5%;
  margin: 5px;
  cursor: pointer;
}

.primary-icon {
  font-size: 10px;
}

.primary-color {
  font-weight: 600;
  color: #007ABB;
}

.image-card {
  margin-top: -10px;
}

.noPadding {
  padding: 2px;
}


.miu-container {
  margin-left: 10px;
}

.btn {
  border-radius: 10px !important;
  height: 35px !important;
}

.whatsapp-chat-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.whatsapp-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: #118C7E;
  margin-bottom: 80px;
  color: white !important;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
}

.whatsapp-btn>i {
  margin-right: 3px;
}