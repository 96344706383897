body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.pd-image-container {
  display: flex;
  /* height: 800px; */
  width: 100%;
  padding-top: 20px;
}

.pd-image-content {
  flex: 1;
}


.pd-selected-image {}

.pd-selected-image-img {
  height: 400px;
  width: 100%;
  background-size: cover;
}

.pd-showcase-images {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pd-showcase-image {
  margin: 10px;
  height: 60px;
  width: 90px;
  border-radius: 5px;
  background-size: cover;
  transition: 0.3s;
  cursor: pointer;
}

.pd-showcase-image.active {
  background-color: rgba(0, 0, 0, 0.7);
}

.pd-showcase-image:hover {
  filter: brightness(50%);
}


.pd-seller-section {
  flex: 0.4;
  padding: 20px 00px 0px 30px;

}

.pd-seller-container {
  display: flex;
  flex-direction: "row";
}


.pd-seller-section-content {
  flex: 1;
}

.pd-property-name {
  color: #070F43;
  font-size: 20px;
  font-weight: bold;
  min-height: 48px;
}

.pd-property-address {
  font-size: 12px;
}

.pd-features {
  margin-top: 10px;
}

.pd-feature-item {
  margin-top: 10px;
}


.pd-price-section {
  flex: 0.4;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.pd-price {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 18px;
}

.pd-purpose {
  background-color: #295195;
  border-radius: 15px;
  padding: 3px 10px;
  font-size: 10px;
  font-weight: bold;
  color: white;
}

.pd-seller-details {
  margin-top: 177px;
  display: flex;
  align-items: center;
  border: 1px solid #C6C8DF;
  border-radius: 5px;
  padding: 5px;


}

.pd-seller-image-container {
  flex: 0.4
}

.pd-seller-image {
  height: 30px;
  width: 30px;
  /* margin-right: 8px; */
  background-size: cover;
  border-radius: 100px;
}

.pd-seller-info {
  flex: 1.6
}

.pd-seller-name {
  font-weight: 500;
}

.pd-seller-ownership {
  margin-top: -7px;
  font-size: 11px;
}

.pd-listing-date {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.favourite-section {
  margin-top: 10px;
}

.favourite-icon {
  font-size: 24px;
}

.favourite-icon.active {
  color: #D10D19;
}

.pd-detail-container {
  margin-top: 25px;
}

.pd-heading {
  font-size: 20px;
  margin: 15px 0px;
}

.label.label-success {
  border-radius: 5px;
  float: right;
  margin-right: 20px;
  background-color: #295195;
}


.pd-amentities-heading {
  margin-top: 114px;
}

.pd-amentities-section {
  padding: 12px 15px;
  min-height: 186px;
}


.pd-amenity-indoor {
  margin-top: 20px;
}

.pd-amenity-title {
  font-size: 12px !important;

}

.pd-amentities-container {
  margin-top: 10px;
  display: flex;
}

.pd-amenity-checkbox {
  padding-top: 5px;
  /* flex: 1; */
  /* flex-wrap: wrap !important; */
}

.pd-floorplan{
  width: auto;
  height: 400px;
}

.pd-description-container{
  min-height: 400px;
}

.pd-video{
  width: 100%;
}